body, html {
  overflow-x: hidden;
    margin: 0;
    padding: 0;
}

  @font-face {
    font-family: 'Rubik-Bold';
    src: url('./assets/fonts/Rubik-Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Rubik-BoldItalic';
    src: url('./assets/fonts/Rubik-BoldItalic.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Rubik-ExtraBold';
    src: url('./assets/fonts/Rubik-ExtraBold.ttf') format('truetype');
  }

  
  @font-face {
    font-family: 'Rubik-SemiBold';
    src: url('./assets/fonts/Rubik-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Rubik-SemiBoldItalic';
    src: url('./assets/fonts/Rubik-SemiBoldItalic.ttf') format('truetype');
  }
  